var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: {
        selectbox: true,
        filterMode: _vm.filterMode,
        disabled: _vm.disabled,
      },
      on: { click: _vm.switchFragment },
    },
    [
      _c(
        "div",
        { staticClass: "selected" },
        [
          !_vm.selected
            ? _c("div", { staticClass: "placeholder font-sm" }, [
                _vm._v(_vm._s(_vm.placeholder)),
              ])
            : _c(_vm.selectBoxItemComponent, {
                tag: "component",
                class: { "selected-item": true, filterMode: _vm.filterMode },
                attrs: {
                  item: _vm.selected,
                  label: _vm.selected.name,
                  icon: _vm.selected.image,
                  iconPlaceholder: _vm.iconPlaceholder,
                  disableHover: "",
                  filterMode: _vm.filterMode,
                  grayColor: _vm.grayColor,
                },
              }),
          !_vm.filterMode
            ? _c("img", {
                staticClass: "icon-sm",
                attrs: { src: require("@/assets/img/icon/select.png") },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSelecting && !_vm.disabled,
              expression: "isSelecting && !disabled",
            },
          ],
          class: { list: true, grayColor: _vm.grayColor },
        },
        [
          _vm.enableUnselected
            ? _c(_vm.selectBoxItemComponent, {
                tag: "component",
                staticClass: "list-item",
                attrs: {
                  label: _vm.placeholder,
                  icon: "none",
                  filterMode: _vm.filterMode,
                  grayColor: _vm.grayColor,
                },
                on: { select: _vm.unselect },
              })
            : _vm._e(),
          _vm._l(_vm.dataItems, function (item) {
            return _c(_vm.selectBoxItemComponent, {
              key: item[_vm.keyAttr],
              tag: "component",
              staticClass: "list-item",
              attrs: {
                item: item,
                label: item.name,
                icon: item.image,
                iconPlaceholder: _vm.iconPlaceholder,
                filterMode: _vm.filterMode,
                grayColor: _vm.grayColor,
                disabled: item.disabled,
              },
              on: { select: _vm.selectItem },
            })
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }